<template>

    <Editor :style="editorStyle"
            class="singleLineEditor"
            v-model="text"
            :api-key="TINY_MCE"
            :init="editorOptions"  />


</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: "wsEditorRegular",
  props : ['value','editorStyle','placeholder'],
  components : {
    Editor
  },
  data() {
    return {
      text : ''
    }
  },
  watch : {
    text(val) {
      this.$emit('input',val)
    },
    value(val) {
      if ( this.text !== this.value ) {
        this.text = val
      }
    }
  },
  computed : {
    editorOptions() {
      return {
        height: 100,
        minWidth : 600,
        plugins: 'powerpaste',
        toolbar: false,
        menubar: false,
        inline: true,
        force_br_newlines : true,
        forced_root_block : false,
        statusbar: false,
        placeholder: this.placeholder,
        valid_elements: 'strong,em,span[style],a[href]',
        valid_styles: {
          '*': 'font-size,font-family,color,text-decoration,text-align'
        },
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
        skin: 'naked',
        setup: this.editorSetup,


        browser_spellcheck: true,
       // content_style: 'div { font  }'
        // skins : naked document outside
      }
    },
  },
  methods : {
    editorSetup(editor) {
      editor.on('init', () => {
        this.$emit('loaded',true)
      });
      editor.on('blur', () => {
        this.$emit('blur')
      });

    }
  },

  mounted() {
    if (this.value ) { this.text=this.value }
  }
}
</script>

<style scoped>
.singleLineEditor {
  outline : none
}
.singleLineEditor *[contentEditable="true"]:focus,
.singleLineEditor *[contentEditable="true"]:hover {
  outline: 2px solid #d21935;
}
</style>